import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
   html,
   html *,
   html *::before,
   html *::after {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
   }
   body {
      font-family: 'Urbanist', sans-serif;
      font-size: 1rem;
		line-height: 150%;
   }
   ul {
      list-style: none;
   }
   button {
      font-family: 'Urbanist', sans-serif;
      border: none;
      cursor: pointer;
      font-weight: 600;
      outline: none;
   }
   a {
      text-decoration: none;
      color: inherit;
   }
   h1,
   h2,
   h3,
   h4,
   h5,
   h6{
      font-weight: 700;
   }
   .Toastify__toast-container {
      padding: 0;
      width: auto;

      @media screen and (max-width:480px) {
         &.Toastify__toast-container--bottom-center {
            bottom: 1em;
            left: 50%;
            transform: translateX(-50%);
         }
         &.Toastify__toast-container--top-center {
            top: 1em;
            left: 50%;
            transform: translateX(-50%);
         }
      }
      
      .Toastify__toast {
         padding: 0;
         margin-bottom: 0;
         min-height: unset;
         background: none;
      }
      .Toastify__toast-body {
         padding: 0;
      }
   }
`;

export default GlobalStyles;
