import styled from 'styled-components';
import { maxQuery, minQuery } from '../../utilities';

export const Talent = styled.div`
	--bg-color: #fff0e3;
	--text-color: #ff9232;

	border-radius: 1.27325rem;
	background: var(--bg-color);
	padding: 2rem;
	flex: 1;
	min-width: 16.875rem;
	max-width: calc(100vw - 4rem);

	&.in-view {
		${minQuery('md')} {
			margin-top: 6.6rem;
		}
	}

	${maxQuery('352px')} {
		min-width: calc(100vw - 4rem);
	}

	svg {
		display: block;
		margin: 0 0 0.3rem auto;
	}
	b {
		display: block;
		color: var(--text-color);

		font-size: 1.01188rem;
		font-size: clamp(1rem, 0.9852rem + 0.0475vw, 1.01188rem);

		font-weight: 700;
		line-height: 177%;
	}
	h4 {
		font-size: 1.26763rem;
		font-size: clamp(1.25rem, 1.228rem + 0.0705vw, 1.26763rem);

		color: #070d19;
		font-weight: 600;
		line-height: 140%;
		text-transform: capitalize;
		margin: 0.5rem 0 1rem;
	}
	p {
		font-size: 0.88738rem;
		font-size: clamp(0.875rem, 0.8595rem + 0.0495vw, 0.88738rem);

		color: #070d19;
		line-height: 170%;
	}
`;
export const Container = styled.section`
	position: relative;

	padding: 6.25rem 8.5rem 7rem;
	padding: 6.25rem clamp(2rem, -1.75rem + 12vw, 8.75rem) 7rem;

	h2 {
		color: #141f39;
		text-align: center;

		font-size: 2.7985rem;
		font-size: clamp(2rem, 0.8022rem + 3.194vw, 2.7985rem);

		letter-spacing: -0.12675rem;
		text-transform: capitalize;
		line-height: 110%;
		position: relative;

		::before {
			content: '';
			width: 16%;
			max-width: 8.875rem;
			min-width: 2rem;
			height: 0.3125rem;
			background: #4060ea;
			position: absolute;
			top: 2.7985rem;
			top: clamp(2rem, 0.8022rem + 3.194vw, 2.7985rem);
			left: 50%;
			transform: translateX(-50%);
		}
	}
	h2 + p {
		color: #4f4f4f;
		text-align: center;

		font-size: 1.25rem;
		font-size: clamp(0.75rem, 0rem + 2vw, 1.25rem);

		line-height: 130%;
		margin-top: 0.8rem;

		${maxQuery('sm')} {
			margin-top: 0.5rem;
		}
	}
	.talents-con {
		display: flex;
		gap: 1.2rem;
		overflow: auto;
		padding-bottom: 1rem;

		margin-top: 5rem;
		margin-top: clamp(2.5rem, 1.1111rem + 4.4444vw, 5rem);

		${minQuery('md')} {
			gap: 1.6rem;
			align-items: flex-start;
		}

		${Talent}:nth-child(2) {
			--bg-color: #cdfff3;
			--text-color: #007f61;

			transition: margin-top 0.6s ease-out;
		}
		${Talent}:nth-child(3) {
			--bg-color: #ffe2f8;
			--text-color: #ff4acb;
		}
		${Talent}:nth-child(4) {
			--bg-color: #eee6fc;
			--text-color: #8d51f9;

			transition: margin-top 0.6s ease-out;
		}
	}
	svg.bg {
		position: absolute;
		left: 53.8%;
		bottom: 6.91rem;
		z-index: -1;
	}
`;
