import styled from 'styled-components';
import { HeroBg } from '../../assets/svgs';
import { maxQuery } from '../../utilities';

export const Container = styled.section`
	background: url(${HeroBg}) no-repeat #eff3fb;
	background-size: cover;
	text-align: center;
	overflow: hidden;

	> div:first-child {
		padding: 11.5rem 8.5rem 5rem;
		padding: 11.5rem clamp(2rem, -1.75rem + 12vw, 8.75rem) 5rem;
		color: #070d19;

		h1 {
			font-family: 'Rethink Sans', sans-serif;
			font-size: 4.5rem;
			font-size: clamp(2.5rem, 1.4362rem + 3.4043vw, 4.5rem);

			line-height: 1.2;
			letter-spacing: -0.04rem;

			${maxQuery(900)} {
				br {
					display: none;
				}
			}

			span {
				position: relative;

				&.ups svg {
					position: absolute;
					bottom: 2px;
					right: 0.35em;
					width: 110%;
				}
				&.seasoned svg {
					position: absolute;
					bottom: -5px;
					left: 0%;
					width: 36%;
					height: auto;
				}
				&.talent {
					color: #4060ea;

					::after {
						display: none;
						color: #000;
					}
					&.start::after {
						display: inline;
					}
				}
				&.done::after {
					display: none !important;
				}
			}
		}
		p {
			margin: 1.5rem auto 2rem;
			line-height: 160%;
			max-width: 44ch;
		}
		div {
			display: flex;
			gap: 1.5rem;
			gap: clamp(0.75rem, -0.375rem + 3vw, 1.5rem);
			justify-content: center;

			${maxQuery('550px')} {
				> * {
					flex: 1;
				}
				a button {
					width: 100%;
				}
				button {
					padding: 1rem 2.5rem;
					padding: clamp(0.8rem, 0.6rem + 0.64vw, 1rem) clamp(0.75rem, 0.05rem + 2.8vw, 2.5rem);
				}
			}
			${maxQuery('370px')} {
				flex-direction: column;
			}

			button {
				justify-content: center;
			}
		}
	}

	.swiper {
	}
	> .swiper-con {
		${maxQuery('sm')} {
			display: none;
		}
		.swiper-slide {
			width: 75vw;
		}
		img {
			width: 100%;
			transform: translateY(7rem) scale(0.9);
			transition: transform 700ms;

			&.active {
				transform: translateY(0.625rem) scale(1);
			}
		}
	}
`;
