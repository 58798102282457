import { Link } from 'react-router-dom';
import { FAQMinus, FAQPlus } from '../../assets/svgs';
import { Container } from './styles';
import { Approutes } from '../../constants';

const FAQ = () => {
	return (
		<Container>
			<header>
				<h1>FAQ</h1>
				<p>Last updated Feb 2022</p>
			</header>

			<section>
				<div>
					<div className="heading" onClick={(event) => event.currentTarget.parentElement.classList.toggle('active')}>
						<FAQMinus />
						<FAQPlus />
						<h4>What is DESCINDER?</h4>
					</div>
					<div className="body">
						<p>
							Descinder is dedicated to empowering early businesses (Start-ups & SMEs) by providing opportunities for talented early-career
							individuals (Interns & Fresh Graduates) from diverse educational institutions. These opportunities extend to both traditional onsite
							settings such as schools and universities, as well as virtual platforms offering code and online training.
						</p>
					</div>
				</div>
				<div>
					<div className="heading" onClick={(event) => event.currentTarget.parentElement.classList.toggle('active')}>
						<FAQMinus />
						<FAQPlus />
						<h4>WHO CAN REGISTER ON DESCINDER?</h4>
					</div>
					<div className="body">
						<p>
							Whether you're a business (start-up/SME) seeking talent or an individual (Intern or Fresh Graduate) looking for opportunities, you
							can easily get started on Descinder. Simply create an account, fill out your profile, and select a subscription plan to unlock the
							full benefits of the platform.
						</p>
					</div>
				</div>
				<div>
					<div className="heading" onClick={(event) => event.currentTarget.parentElement.classList.toggle('active')}>
						<FAQMinus />
						<FAQPlus />
						<h4>Can I change the company once I start my internship?</h4>
					</div>
					<div className="body">
						<p>
							After commencing your internship, changing the company you're working for isn't feasible since your are already linked to the
							specific company where you're interning.
						</p>
					</div>
				</div>
				<div>
					<div className="heading" onClick={(event) => event.currentTarget.parentElement.classList.toggle('active')}>
						<FAQMinus />
						<FAQPlus />
						<h4>What is the refund policy?</h4>
					</div>
					<div className="body">
						<p>
							If descinder fails to find you an internship that you are happy with, we will refund all your money in full. You can read more about
							the refund policy on our <Link to={Approutes.docs.refund_policy}>refund policy</Link> page
						</p>
					</div>
				</div>
				<div>
					<div className="heading" onClick={(event) => event.currentTarget.parentElement.classList.toggle('active')}>
						<FAQMinus />
						<FAQPlus />
						<h4>Do I need a degree to be eligible for the graduate internships?</h4>
					</div>
					<div className="body">
						<p>
							Our internship programs cater to both on-site and online certification-seeking interns and recent graduates from various educational
							institutions, encompassing traditional schools and online platforms like Coursera, Edx, Future Learn, and more. The eligibility
							criteria for participation are aligned with degree standards, ensuring a diverse and comprehensive pool of talent.
						</p>
					</div>
				</div>
				<div>
					<div className="heading" onClick={(event) => event.currentTarget.parentElement.classList.toggle('active')}>
						<FAQMinus />
						<FAQPlus />
						<h4>What kind of companies are available for internships?</h4>
					</div>
					<div className="body">
						<p>
							A diverse array of companies, ranging from innovative startups to thriving SMEs, has converged on our platform. These companies
							represent various sectors of the economy, and their presence here is centered around the collaborative creation of projects and the
							streamlined assignment of tasks. The platform serves as a dynamic hub where businesses from different industries can come together,
							fostering a collaborative environment for project development and task allocation.
						</p>
					</div>
				</div>
				<div>
					<div className="heading" onClick={(event) => event.currentTarget.parentElement.classList.toggle('active')}>
						<FAQMinus />
						<FAQPlus />
						<h4>What are the eligibility criteria to qualify for an internshiP?</h4>
					</div>
					<div className="body">
						<p>
							To access opportunities on our platform, you need to demonstrate that you have undergone or are currently undergoing learning
							facilitation. This could be through an on-site institution like a university or academy, or via online certification platforms such
							as Coursera, FutureLearn, Edx, etc. Proof of completion from a recognized institution unlocks opportunities on the platform.
						</p>
						<br />
						<p>
							Upon creating an account and completing your profile registration, you'll be required to provide essential information, including
							your email address, contact number, password, applicant's avatar (optional), applicant's name, a brief introduction about yourself,
							applicant type (intern/fresh graduate), highest level of education, area of study, recent institution attended, and other relevant
							details.
						</p>
					</div>
				</div>
				<div>
					<div className="heading" onClick={(event) => event.currentTarget.parentElement.classList.toggle('active')}>
						<FAQMinus />
						<FAQPlus />
						<h4>What are the eligibility criteria to register your business?</h4>
					</div>
					<div className="body">
						<p>
							To access opportunities on our platform, you need to demonstrate that you have undergone or are currently undergoing learning
							facilitation. This could be through an on-site institution like a university or academy, or via online certification platforms such
							as Coursera, FutureLearn, Edx, etc. Proof of completion from a recognized institution unlocks opportunities on the platform.
						</p>
						<br />
						<p>
							Upon creating an account and completing your profile registration, you'll be required to provide essential information, including
							your email address, contact number, password, applicant's avatar (optional), applicant's name, a brief introduction about yourself,
							applicant type (intern/fresh graduate), highest level of education, area of study, recent institution attended, and other relevant
							details.
						</p>
					</div>
				</div>
			</section>
		</Container>
	);
};
export default FAQ;
