import styled, { keyframes } from 'styled-components';
import { maxQuery } from '../../utilities';
import { ConnectingBg } from '../../assets/svgs';

const slide = keyframes`
0%, 20%{
	transform: translateX(-50%);
}
40%,60%{
	transform: translateX(-100%);
}
100%{
	transform: translateX(-50%);
}
`;

export const Container = styled.section`
	padding: 7.5rem 8.5rem;
	padding: 7.5rem clamp(2rem, -1.75rem + 12vw, 8.75rem);
	background: url(${ConnectingBg}) no-repeat #f7f9fd;
	background-size: cover;
	font-family: 'Rethink Sans', sans-serif;

	h3 {
		color: #05060f;
		font-size: 3rem;
		font-size: clamp(2rem, 1.4444rem + 1.7778vw, 3rem);
		line-height: 1.3;
		text-align: center;
		margin-bottom: 3.89rem;
		text-transform: capitalize;

		${maxQuery('md')} {
			br {
				display: none;
			}
		}

		span {
			position: relative;
			display: inline-block;

			&.blue::before {
				background: #4060ea;
				left: 0;
				width: 70%;
			}
			&.yellow::before {
				background: #f2c94c;
				right: 0;
				width: 82%;
			}

			::before {
				content: '';
				min-width: 2rem;
				height: 0.3125rem;
				position: absolute;
				top: 3.2rem;
				top: clamp(2.3rem, 1.7444rem + 1.7778vw, 3.3rem);
			}
		}
	}

	> div {
		display: grid;
		grid-template-columns: 6fr 4fr;
		gap: 1.5rem 3.38rem;
		gap: 1.5rem clamp(2rem, 1.2333rem + 2.4533vw, 3.38rem);
		max-width: 1700px;
		margin: auto;

		${maxQuery('lg')} {
			grid-template-columns: 1fr;
		}

		.box {
			overflow: hidden;
			border-radius: 1.125rem;
			padding: 4rem 2rem 3.77rem;
			padding: clamp(2rem, 0.8889rem + 3.5556vw, 4rem) clamp(1rem, 0.4444rem + 1.7778vw, 2rem) clamp(2rem, 1.0167rem + 3.1467vw, 3.77rem);

			:nth-child(1) {
				background: #f2c94c;
				color: #05060f;

				.heading {
					display: flex;
					gap: 0.5rem;
					align-items: flex-start;
				}
			}

			:nth-child(2) {
				color: #fff;
				background: #4060ea;
				padding: 4rem 0 3.77rem;
				padding: clamp(2rem, 0.8889rem + 3.5556vw, 4rem) 0 clamp(2rem, 1.0167rem + 3.1467vw, 3.77rem);

				big {
					padding: 0 2rem;
					padding: 0 clamp(1rem, 0.4444rem + 1.7778vw, 2rem);
					margin-bottom: 2rem;
				}
			}

			big {
				font-size: 2rem;
				font-size: clamp(1.5rem, 1.2222rem + 0.8889vw, 2rem);
				font-weight: 700;
				line-height: 1.3;
				display: block;
			}
			p {
				color: #070d19;
				font-size: 1.25rem;
				font-size: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);
				line-height: 1.4;
			}
			.startup-perks {
				margin-top: 2.94rem;

				.swiper-slide {
					border-radius: 0.875rem;
					background: #fff;
					color: #090b0c;
					text-align: center;
					font-size: 0.75rem;
					font-weight: 700;
					line-height: 1.3;
					width: 9.5rem;
					height: 13.25rem;
					flex-shrink: 0;
					padding: 2rem 0.8rem;
					display: flex;
					flex-direction: column;

					img,
					svg {
						display: block;
						max-width: 100%;
						height: 6.625rem;
						margin-bottom: 0.5rem;
						object-fit: contain;
					}
				}
			}
			.skills-con {
				overflow: hidden;

				> div {
					:not(:last-child) {
						margin-bottom: 0.71rem;
					}
					:nth-child(2) {
						padding-left: 1.94rem;
					}
				}
				.skill {
					border-radius: 0.82413rem;
					background: #fff;
					padding: 0.71rem 1.41rem;
					display: flex;
					align-items: center;
					gap: 0.47rem;
					font-family: 'Urbanist', sans-serif;
					font-size: 0.71rem;
					line-height: 1.2;
					flex-shrink: 0;
					min-width: 15rem;
					width: max-content;

					b {
						font-weight: 800;
						color: #000;
						display: block;
					}
					span {
						color: #6b6b6b;
					}
				}
			}
			.animated {
				margin-top: 2.5rem;
				/* position: relative;
				left: 50%;
				transform: translateX(-50%);
				animation: ${slide} 10s ease-in-out infinite; */
			}
		}
	}
`;
