import { useEffect } from 'react';
import { FreshTalentStar, TalentFour, TalentOne, TalentThree, TalentTwo } from '../../assets/svgs';
import { Container, Talent } from './styles';
import { useRef } from 'react';

const FreshTalent = () => {
	const talentTwo = useRef();
	const talentFour = useRef();

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						entry.target.classList.add('in-view');
						observer.unobserve(entry.target);
					}
				});
			},
			{ rootMargin: '0px 0px -300px 0px' }
		);

		observer.observe(talentTwo.current);
		observer.observe(talentFour.current);

		return () => observer.disconnect();
	}, []);

	return (
		<Container id="why">
			<h2>Build Products with Fresh Talents</h2>
			<p>We combine vast industry knowledge with advanced technical competence</p>

			<div className="talents-con">
				<Talent>
					<TalentOne />
					<b>01</b>
					<h4>Product Design</h4>
					<p>
						With their creative thinking, out-of-the-box ideas, and ability to stay updated with the latest design trends, these young minds bring
						a breath of fresh air to the design process.
					</p>
				</Talent>

				<Talent ref={talentTwo}>
					<TalentTwo />
					<b>02</b>
					<h4>Data & Business Analytics</h4>
					<p>"Fresh talent contribute new perspectives, energy, and skill sets, enhancing data and business analytics with innovation."</p>
				</Talent>

				<Talent>
					<TalentThree />
					<b>03</b>
					<h4>Software Development</h4>
					<p>
						Fresh talents bring innovation and current technical skills to software development, helping start-ups build cutting-edge products and
						stay ahead in the fast-paced tech industry.
					</p>
				</Talent>

				<Talent ref={talentFour}>
					<TalentFour />
					<b>04</b>
					<h4>Product Engineering</h4>
					<p>
						Fresh talent comes with a mix of creativity, experimentation, and the latest engineering knowledge, empowering start-ups to create
						innovative solutions for their target market.
					</p>
				</Talent>
			</div>

			<FreshTalentStar className="bg" />
		</Container>
	);
};

export default FreshTalent;
