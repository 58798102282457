import { Container } from './styles';

const CodeOfConduct = () => {
	return (
		<Container>
			<header>
				<h1>Code Of Conduct</h1>
				<p>Last updated Feb 2022</p>
			</header>

			<section>
				{/* 9. DESCINDER CODE OF CONDUCT */}
				<p>
					This platform is a global community where professionals connect, collaborate, and seize new opportunities. To maintain a positive and
					productive environment, all users are required to adhere to the Descinder Code of Conduct. We appreciate you using Descinder, a
					platform where professionals from across the world congregate to find work, keep updated, pick up new skills, and cultivate fruitful
					relationships. By working together, we can transform our community into a place where people can communicate, learn, and develop, which
					will help everyone have access to economic opportunities. Please review these policies to ensure your contributions align with our
					community standards.
				</p>
				<br />
				<h4>Be Reliable</h4>
				<p>
					We expect users to use their real names on Descinder, provide accurate information about themselves or their company, and publish only
					genuine and true content. The accuracy and authenticity of your contributions add to the overall integrity of our community.
				</p>
				<br />
				<h4>Act with professionalism.</h4>
				<p>
					Descinder is a platform designed to connect SMEs and Startups with early career individuals globally, fostering productivity and
					success. Users are advised to engage in in-depth discussions regarding the workplace, while maintaining formal communication. All
					members must interact civilly and respectfully, refraining from engaging in rude, improper, or disrespectful behaviour towards others.
					Upholding professionalism ensures Descinder remains a platform where professionals can thrive. By using Descinder, you acknowledge and
					agree to adhere to the Descinder Code of Conduct. Failure to comply may result in corrective action, including account suspension or
					termination.
				</p>
				<br />
				<h4>Appendix to the Code of Conduct</h4>
				<p>
					This appendix serves as a supplemental section to our Code of Conduct, offering users valuable resources, templates, and further
					guidance to enhance the understanding and application of the established principles. Please refer to the following materials within
					this appendix as needed, ensuring a comprehensive and informed approach to fostering a positive and respectful community within our
					platform.
				</p>
				<br />
				<p>1. User Behaviour: Users are expected to engage respectfully and professionally in all interactions within the platform.</p>
				<br />
				<p>
					2. Punctuality: Professional Community Policies on Descinder mandates all users to be punctual on the delivery of projects. Users are
					required to attend meetings punctually, respecting the agreed-upon schedules.
				</p>
				<br />
				<p>3. Time Management: Users are accountable for meeting time sensitive deadlines in the completion of tasks and projects.</p>
				<br />
				<p>4. Task Logging: It is mandatory for users to log completed tasks promptly, ensuring transparency and record-keeping.</p>
				<br />
				<p>
					5. Zero Tolerance for Abuse: Verbal, physical, or emotional abuse towards any individual will not be tolerated within the platform.
				</p>
				<br />
				<p>
					6. High Standards for Task Delivery: Users are expected to maintain high and professional standards when delivering tasks, utilizing
					approved templates when applicable.
				</p>
				<br />
				<p>
					7. Adherence to Work Ethics: All users must adhere to established work ethics, promoting a positive and respectful work environment.
				</p>
				<br />
				<p>
					8. Intern Workload: Startups are prohibited from overloading interns with excessive tasks, ensuring a reasonable and manageable
					workload.
				</p>
				<br />
				<p>
					9. Intern Work Schedule: Interns are required to adhere to the standard 20-hour weekly work schedule, promoting a balanced and
					sustainable work-life experience.
				</p>
			</section>
		</Container>
	);
};
export default CodeOfConduct;
