import styled from 'styled-components';
import { ContactBg } from '../../assets/svgs';
import { maxQuery } from '../../utilities';

export const Container = styled.section`
	padding: 4rem 8.5rem 4rem;
	padding: clamp(3.5rem, 3.234rem + 0.8511vw, 4rem) clamp(2rem, -1.75rem + 12vw, 8.75rem) clamp(3.5rem, 3.234rem + 0.8511vw, 4rem);

	background: url(${ContactBg}) no-repeat #f7f9fd;
	background-size: cover;

	h3 {
		color: #070d19;
		font-size: 3.125rem;
		font-size: clamp(2rem, 1.4016rem + 1.9149vw, 3.125rem);

		line-height: 130%;
		text-align: center;
	}
	> p {
		text-align: center;
		margin: auto;
		max-width: 78ch;
		color: #333;
		font-size: 1rem;
		font-size: clamp(0.75rem, 0.617rem + 0.4255vw, 1rem);

		line-height: 150%;
	}
	> div {
		display: grid;
		grid-template-columns: 40% 1fr;
		max-width: 50rem;
		margin: 2.5rem auto 0;
		margin: clamp(2rem, 1.734rem + 0.8511vw, 2.5rem) auto 0;

		${maxQuery('md')} {
			display: flex;
			flex-direction: column-reverse;
			gap: 1.6rem;
			max-width: 100%;
		}
	}
	.info {
		padding-right: 2rem;
		margin: auto 0;
		text-align: right;

		${maxQuery('md')} {
			padding-right: 0;

			br {
				display: none;
			}
		}

		p {
			font-size: 0.875rem;
			text-align: right;
			margin-bottom: 2rem;
			margin-bottom: clamp(1rem, 0.4681rem + 1.7021vw, 2rem);

			${maxQuery('md')} {
				text-align: center;
			}
		}
		div {
			display: flex;
			justify-content: flex-end;
			gap: 0.5rem;
			margin-top: 1rem;
			margin-top: clamp(0.5rem, 0.234rem + 0.8511vw, 1rem);

			align-items: center;
			color: #333;
			font-size: 1rem;
			font-size: clamp(0.75rem, 0.617rem + 0.4255vw, 1rem);

			line-height: 150%;

			${maxQuery('md')} {
				justify-content: center;
			}
		}
	}
	form {
		padding-left: 2rem;
		border-left: 1px solid #1a1a1a66;

		${maxQuery('md')} {
			padding-left: 0;
			border-left: unset;
		}

		input,
		textarea {
			margin-bottom: 1rem;
			display: block;
			width: 100%;
			border-radius: 0.1875rem;
			border: 1px solid #c2d1d9;
			background: #fff;
			padding: 1.2rem 1.8rem;
			color: #070d19;
			font-family: inherit;
			font-size: 0.875rem;
			line-height: 130%;
		}
		textarea {
			resize: vertical;
		}
		button {
			margin-top: 2rem;
			width: 100%;
			justify-content: center;
		}
	}
`;
