import styled from 'styled-components';
import { maxQuery } from '../../../utilities';

export const Container = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 50;
	background: #eff3fb;
	padding: 1rem 8.5rem;
	padding: clamp(0.3rem, -0.0889rem + 1.2444vw, 1rem) clamp(2rem, -1.75rem + 12vw, 8.75rem);

	&.bright {
		background: #fff;
		box-shadow: 0px 0px 8px #eee;
	}
	${maxQuery('xl')} {
		&.active {
			color: #eff3fb;
			background: #070d19;
			bottom: 0;

			.logo-con,
			.control-con {
				> svg:nth-child(1) {
					display: none;
				}
				> svg:nth-child(2) {
					display: block;
				}
			}

			nav {
				display: flex;
			}
		}
	}

	> div {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.logo-con svg {
		flex-shrink: 0;
		width: clamp(8.3rem, 6.1191rem + 6.9787vw, 12.4rem);
		height: 70px;
	}
	.logo-con,
	.control-con {
		> svg:nth-child(2) {
			display: none;
		}
	}

	nav {
		&,
		ul {
			display: flex;
			align-items: center;
			justify-content: space-between;

			gap: 1.5rem;
			gap: clamp(1rem, 0.1rem + 1.6vw, 1.5rem);

			${maxQuery('xl')} {
				flex-direction: column;
				align-items: flex-start;
			}
		}

		${maxQuery('xl')} {
			display: none;
			position: absolute;
			top: 9rem;

			> button {
				font-size: 1rem;
			}
		}

		li button {
			display: inline-block;
			font-weight: 600;
			line-height: 120%;

			padding: 1.5rem;
			padding: clamp(1rem, 0.1rem + 1.6vw, 1.5rem);

			font-size: 1.125rem;
			font-size: clamp(1rem, 0.8333rem + 0.3333vw, 1.125rem);
			background: transparent;
			color: inherit;

			:hover {
				color: #4060ea;
			}

			${maxQuery('xl')} {
				font-weight: 500;
				padding: 0.5rem 0;
			}
		}
	}
	.control-con {
		display: none;
		cursor: pointer;

		${maxQuery('xl')} {
			display: block;
		}

		svg:active {
			outline: none;
		}

		.close:hover path {
			fill: #4060ea;
		}
	}
`;
