import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import {
	Playground,
	Home,
	TermsOfUse,
	PrivacyPolicy,
	CodeOfConduct,
	CommunityGuidelines,
	FAQ,
	CoreValues,
	PageNotFound,
	RefundPolicy,
} from './pages';
import { Approutes } from './constants';
import { ScrollToTop, UseAnalytics } from './components';
import { GlobalStyles, theme } from './base';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppLayout, DocsLayout } from './layout';

function App() {
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles />

			<BrowserRouter>
				<Routes>
					{/* Playground page for developers */}
					{/* Not visible in production */}
					{process.env.NODE_ENV === 'development' && <Route path={Approutes.playground} element={<Playground />} />}

					<Route element={<AppLayout />}>
						<Route path={Approutes.home} element={<Home />} />

						<Route element={<DocsLayout />}>
							<Route path={Approutes.docs.index} element={<Navigate to={Approutes.docs.terms_of_use} replace />} />
							<Route path={Approutes.docs.code_of_conduct} element={<CodeOfConduct />} />
							<Route path={Approutes.docs.community_guidelines} element={<CommunityGuidelines />} />
							<Route path={Approutes.docs.core_values} element={<CoreValues />} />
							<Route path={Approutes.docs.faq} element={<FAQ />} />
							<Route path={Approutes.docs.privacy_policy} element={<PrivacyPolicy />} />
							<Route path={Approutes.docs.refund_policy} element={<RefundPolicy />} />
							<Route path={Approutes.docs.terms_of_use} element={<TermsOfUse />} />
						</Route>
					</Route>

					<Route path="*" element={<PageNotFound />} />
				</Routes>

				<ScrollToTop />
				<UseAnalytics />
			</BrowserRouter>

			<ToastContainer limit={1} autoClose={3500} newestOnTop={true} closeButton={false} position="top-center" hideProgressBar={true} />
		</ThemeProvider>
	);
}

export default App;
