import { SeamlessLogo } from '../../assets/svgs';
import { Container } from './styles';

const SeamlessSection = () => {
	return (
		<Container>
			<div>
				<div className="logo">
					<SeamlessLogo />
				</div>
				<p>"Join now for a seamless journey, linking startups & SMEs with a diverse pool of talent!"</p>
				<a href="https://app.descinder.com/signup">
					<button>Get Started Now</button>
				</a>
			</div>
		</Container>
	);
};

export default SeamlessSection;
