import { useState } from 'react';
import { Button } from '../../ui';
import { Container } from './styles';
import { INTERN_PAYMENT_PACKAGE, STARTUP_PAYMENT_PACKAGE } from '../../constants';
import { ComingSoonVector, PromoBg, PromoPercent } from '../../assets/svgs';

const SubscriptionPlan = () => {
	const [control, setControl] = useState('startup');

	const PAYMENT_PACKAGE =
		control === 'startup' ? STARTUP_PAYMENT_PACKAGE : control === 'intern' ? INTERN_PAYMENT_PACKAGE.commitment_package : null;

	return (
		<Container id="works">
			<h3>Subscription Fee</h3>
			<p>Here are the subscription packages for both start-ups & SMEs and also that of the interns & graduates</p>

			<div className="controls">
				<Button className={control === 'startup' ? 'active' : ''} onClick={() => setControl('startup')}>
					Start-up & SMEs
				</Button>
				<Button className={control === 'institutions' ? 'active' : ''} onClick={() => setControl('institutions')}>
					Institutions
				</Button>
				{/* <Button className={control === 'intern' ? 'active' : ''} onClick={() => setControl('intern')}>
					Interns & Graduates
				</Button> */}
			</div>

			<div className="hidden"></div>

			{control === 'institutions' ? (
				<>
					<div className="institution-con">
						<ComingSoonVector />
					</div>
				</>
			) : null}

			{PAYMENT_PACKAGE && PAYMENT_PACKAGE.length ? (
				<>
					<div className={`plans ${control === 'intern' ? 'intern' : ''}`}>
						{PAYMENT_PACKAGE.map((paymentPackage) => (
							<div key={paymentPackage.key}>
								{paymentPackage?.has_promo ? (
									<span className="promo">
										<PromoBg className="bg" />

										<span>
											<PromoPercent /> PROMO
										</span>
									</span>
								) : (
									<br />
								)}
								<span className="duration">{paymentPackage.title}</span>
								{paymentPackage?.has_promo ? <span className="price old">£{paymentPackage.old_price}</span> : null}
								<span className={`price ${paymentPackage?.has_promo ? '' : 'no-promo'}`}>£{paymentPackage.price}</span>

								{paymentPackage?.video_link ? (
									<a href={paymentPackage?.video_link} target="_blank" rel="noreferrer" className="video">
										Watch Video TO LEARN
									</a>
								) : null}
								<ul>
									{paymentPackage.perks.map((perk) => (
										<li key={perk}>{perk}</li>
									))}
								</ul>

								<a className="get-started" href={paymentPackage.link}>
									<Button>GET STARTED</Button>
								</a>
							</div>
						))}
					</div>

					<div className="plans mobile">
						{PAYMENT_PACKAGE.map((paymentPackage) => (
							<a key={paymentPackage.key} className="get-started" href={paymentPackage.link}>
								<div>
									{paymentPackage?.has_promo ? (
										<span className="promo">
											<PromoBg className="bg" />

											<span>
												<PromoPercent /> PROMO
											</span>
										</span>
									) : null}

									<span className="duration">{paymentPackage.title}</span>
									<ul>
										{paymentPackage.perks.map((perk) => (
											<li key={perk}>{perk}</li>
										))}
									</ul>
								</div>
								<div>
									{paymentPackage?.has_promo ? <span className="price old">£{paymentPackage.old_price}</span> : null}
									<span className={`price ${paymentPackage?.has_promo ? '' : 'no-promo'}`}>£{paymentPackage.price}</span>

									{paymentPackage?.video_link ? (
										<a href={paymentPackage?.video_link} target="_blank" rel="noreferrer" className="video">
											Watch Video to learn
										</a>
									) : null}
								</div>
							</a>
						))}
					</div>
				</>
			) : null}
		</Container>
	);
};

export default SubscriptionPlan;
