import { Container } from './styles';

const RefundPolicy = () => {
	return (
		<Container>
			<header>
				<h1>Refund Policy</h1>
				<p>Last updated Feb 2022</p>
			</header>

			<section>
				<p>I. Business Subscription Refund:</p>
				<ul>
					<li>
						Payments made during sign-up are non-refundable unless cancelled within seven working days before adding any interns or inviting them
						to start internships.
					</li>
				</ul>
				<br />
				<p>II. Intern Subscription Refund:</p>
				<ul>
					<li>
						Interns are eligible for a refund only if an internship is not secured within 30 days from payment, provided they have submitted
						applications or completed their profiles two months before the internship start date.
					</li>
					<li>Refunds are not applicable once the internship begins.</li>
				</ul>
				<br />
				<p>III. Additional Services (CV, Cover Letter, LinkedIn Optimization):</p>
				<ul>
					<li>
						Refunds are not available for optional services like CV, cover letter, or LinkedIn optimization, as these are considered add-ons to
						our platform.
					</li>
				</ul>
				<br />
				<p>
					Please note that all refund requests are subject to our discretion, and any payments made for additional services are non-refundable.
				</p>
				<p>In the event of a permanent account suspension, Descinder will return any held funds.</p>
			</section>
		</Container>
	);
};

export default RefundPolicy;
