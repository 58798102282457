import { useEffect, useRef } from 'react';
import { LogoDark, HamburgerMenu, LogoLight, CloseMenu } from '../../../assets/svgs';
import { Button } from '../../../ui';
import { Container } from './styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Approutes } from '../../../constants';

const Header = () => {
	const headerRef = useRef();
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const openMobileNav = () => headerRef.current?.classList.add('active');
	const closeMobileNav = () => headerRef.current?.classList.remove('active');

	useEffect(() => {
		let hasSetVisit = sessionStorage.getItem('has_set_num_of_visit') || false;

		if (hasSetVisit) return;

		let numOfVisit = parseInt(localStorage.getItem('num_of_visit') || 0);
		numOfVisit++;

		localStorage.setItem('num_of_visit', numOfVisit);
		sessionStorage.setItem('has_set_num_of_visit', true);
	}, []);

	return (
		<Container ref={headerRef}>
			<div>
				<Link className="logo-con" to={Approutes.home}>
					<LogoDark />
					<LogoLight onClick={closeMobileNav} />
				</Link>

				<div className="control-con">
					<HamburgerMenu tabIndex={0} onClick={openMobileNav} />
					<CloseMenu className="close" tabIndex={0} onClick={closeMobileNav} />
				</div>

				<nav>
					<ul>
						<li>
							<button
								onClick={() => {
									closeMobileNav();
									if (pathname !== Approutes.home) {
										navigate(Approutes.home);

										setTimeout(() => {
											document.querySelector('#why')?.scrollIntoView({ behavior: 'smooth' });
										}, 100);
									} else {
										document.querySelector('#why')?.scrollIntoView({ behavior: 'smooth' });
									}
								}}
							>
								Why Us
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									closeMobileNav();
									if (pathname !== Approutes.home) {
										navigate(Approutes.home);

										setTimeout(() => {
											document.querySelector('#about')?.scrollIntoView({ behavior: 'smooth' });
										}, 100);
									} else {
										document.querySelector('#about')?.scrollIntoView({ behavior: 'smooth' });
									}
								}}
							>
								About Us
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									closeMobileNav();
									if (pathname !== Approutes.home) {
										navigate(Approutes.home);

										setTimeout(() => {
											document.querySelector('#works')?.scrollIntoView({ behavior: 'smooth' });
										}, 100);
									} else {
										document.querySelector('#works')?.scrollIntoView({ behavior: 'smooth' });
									}
								}}
							>
								How It Works
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									closeMobileNav();
									if (pathname !== Approutes.home) {
										navigate(Approutes.home);

										setTimeout(() => {
											document.querySelector('#contact')?.scrollIntoView({ behavior: 'smooth' });
										}, 100);
									} else {
										document.querySelector('#contact')?.scrollIntoView({ behavior: 'smooth' });
									}
								}}
							>
								Contact Us
							</button>
						</li>
					</ul>
					{parseInt(localStorage.getItem('num_of_visit') || 0) >= 3 ? (
						<a href="https://app.descinder.com/login">
							<Button>Login</Button>
						</a>
					) : (
						<a href="https://app.descinder.com/signup">
							<Button>Get Started</Button>
						</a>
					)}
				</nav>
			</div>
		</Container>
	);
};

export default Header;
