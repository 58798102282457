import styled from 'styled-components';

export const Container = styled.div`
	> header {
		margin-bottom: 2.25rem;
		padding: 0 8.5rem;
		padding: 0 clamp(2rem, -1.75rem + 12vw, 8.75rem);

		h1 {
			color: #4060ea;
			text-transform: uppercase;
			font-size: 3rem;
			font-size: clamp(1.5rem, 1rem + 2.2857vw, 3rem);
			line-height: 1.1;
		}
		p {
			font-weight: 500;
			font-size: 1.5rem;
			font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
			line-height: 1.3;
			margin-top: 0.5rem;
		}
	}
	section {
		margin: 0 8.5rem 4rem;
		margin: 0 clamp(2rem, -1.75rem + 12vw, 8.75rem) 4rem;

		a {
			color: #4060ea;
			text-decoration: underline;
		}
		> div:not(:last-child) {
			padding-bottom: 0.625rem;
		}
		> div {
			&.active {
				.heading svg {
					:nth-child(1) {
						display: block;
					}
					:nth-child(2) {
						display: none;
					}
				}
				.body {
					display: block;
				}
			}

			.heading {
				background-color: #fafbfe;
				display: flex;
				align-items: center;
				padding: 1.25rem 3rem;
				padding: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem) clamp(1rem, -0.1111rem + 3.5556vw, 3rem);
				cursor: pointer;

				svg {
					width: 1.25rem;
					width: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);
					height: auto;
					flex-shrink: 0;

					:nth-child(1) {
						display: none;
					}
					:nth-child(2) {
						display: block;
					}
				}

				h4 {
					font-weight: 700;
					font-size: 1.25rem;
					font-size: clamp(1rem, 1.0556rem + 0.2222vw, 1.25rem);
					line-height: 1.7;
					text-transform: uppercase;
					margin-left: 2rem;
					margin-left: clamp(1rem, 0.4444rem + 1.7778vw, 2rem);
				}
			}
			.body {
				padding: 1.4375rem 6.5rem;
				padding: clamp(1rem, 0.7569rem + 0.7778vw, 1.4375rem) clamp(1rem, -2.0556rem + 9.7778vw, 6.5rem);
				display: none;

				p {
					font-size: 1.2rem;
					font-size: clamp(1rem, 0.8889rem + 0.3556vw, 1.2rem);
					line-height: 1.32;
					color: #18191f;
					height: 100%;
					transition: transform 2s cubic-bezier(0.075, 0.82, 0.165, 1);
				}
			}
		}
	}
`;
