import styled from 'styled-components';
import { maxQuery } from '../../utilities';

export const Container = styled.div`
	> header {
		margin-bottom: 2.25rem;
		padding: 0 8.5rem;
		padding: 0 clamp(2rem, -1.75rem + 12vw, 8.75rem);

		h1 {
			color: #4060ea;
			text-transform: uppercase;
			font-size: 3rem;
			font-size: clamp(1.5rem, 1rem + 2.2857vw, 3rem);
			line-height: 1.1;
		}
		p {
			font-weight: 500;
			font-size: 1.5rem;
			font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
			line-height: 1.3;
			margin-top: 0.5rem;
		}
	}
	section {
		padding: 3.125rem 8.5rem;
		padding: clamp(1rem, -0.1806rem + 3.7778vw, 3.125rem) clamp(2rem, -1.75rem + 12vw, 8.75rem);

		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
		gap: 4.35rem 3.5rem;
		gap: clamp(3.5rem, 3.0278rem + 1.5111vw, 4.35rem) clamp(1.5rem, 0.3889rem + 3.5556vw, 3.5rem);

		${maxQuery('sm')} {
			grid-template-columns: 1fr;
		}

		h4 {
			font-weight: 700;
			font-size: 1.5rem;
			font-size: clamp(1.125rem, 0.9167rem + 0.6667vw, 1.5rem);
			line-height: 1.6;
			text-transform: uppercase;
		}
		p {
			font-size: 1.25rem;
			font-size: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);
			line-height: 1.6;
			margin-top: 1em;
			margin-top: clamp(0.5em, 0.2222rem + 0.8889vw, 1em);
		}
		a {
			color: #4060ea;
			text-decoration: underline;
		}
	}
`;
