export const Approutes = {
	home: '/',
	playground: '/playground',
	docs: {
		index: '/docs',
		terms_of_use: '/docs/terms-of-use',
		privacy_policy: '/docs/privacy-policy',
		code_of_conduct: '/docs/code-of-conduct',
		core_values: '/docs/core-values',
		faq: '/docs/faq',
		refund_policy: '/docs/refund-policy',
		community_guidelines: '/docs/community-guidelines',
	},
};
