import { useState } from 'react';
import { EmpoweringImage } from '../../assets/images';
import { TopRightArrow } from '../../assets/svgs';
import { Button } from '../../ui';
import { Container } from './styles';

const navList = [
	{ title: 'Create Project', body: 'Linking Start-Ups & SMEs with emerging professionals for dynamic projects, fostering mutual growth.' },
	{ title: 'Create Tasks', body: 'Create tasks for your project by providing necessary information for assignment to resources.' },
	{ title: 'Assign Tasks', body: 'After creating projects and tasks, you can assign them to your chosen resources.' },
	{
		title: 'Approve Tasks',
		body: 'After tasks are assigned, resources perform them, and then log their reports. The business can then approve the completed tasks.',
	},
];

const EmpoweringSection = () => {
	const [state, setState] = useState(navList[0]);

	return (
		<Container>
			<div>
				<div>
					<h4>Start-ups & SMEs</h4>
					<h3>Empowering Transitions, Connecting Talents</h3>

					<div className="nav">
						{navList.map((item) => (
							<div key={item.title} className={item.title === state?.title ? 'active' : ''} onClick={() => setState(item)}>
								{item.title}
							</div>
						))}
					</div>

					<p>{state?.body}</p>
					<a href="https://app.descinder.com/signup/as-startup">
						<Button>GET STARTED</Button>
					</a>
				</div>
				<div className="img-con">
					<a href="https://app.descinder.com/signup/as-startup">
						<TopRightArrow />
					</a>
					<img src={EmpoweringImage} alt="" loading="lazy" />
				</div>
			</div>
		</Container>
	);
};

export default EmpoweringSection;
