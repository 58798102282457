import { Container } from './styles';

const TermsOfUse = () => {
	return (
		<Container>
			<header>
				<h1>Terms of Use</h1>
				<p>Last updated Feb 2022</p>
			</header>

			<section className="definition">
				<h4>INTRODUCTION</h4>
				<br />
				<p>
					Descinder is a revolutionary platform meticulously crafted to cater to the needs of businesses (start-ups and SMEs) by providing a
					dynamic workforce in the form of Early-career individuals (interns & fresh graduates from accredited institutional learning
					environments). These interns come from diverse academic backgrounds, spanning undergraduate and postgraduate programs, as well as other
					educational and training institutions fostering skill development. The platform serves as a nexus, connecting aspiring early-career
					individuals with ambitious businesses (start-ups and SMEs), fostering a symbiotic relationship that facilitates experiential learning
					and drives, growth, and innovation for businesses.
				</p>
				<br />
				<p>
					The essence of Descinder lies in its commitment to providing a mutually beneficial experience for both parties (the intern, seeking to
					gain real-life and real-time exposure, and the SMEs, seeking to inject fresh perspectives and skills into their operations). This
					synergy cultivates an environment where learning is reciprocal, and innovative solutions emerge from the fusion of academic knowledge
					and practical experience.
				</p>
				<br />
				<p>
					The internship process on Descinder is carefully structured and governed by the terms and conditions established by the companies,
					SMEs, and the platform itself. This ensures a transparent and mutually agreed-upon framework, fostering a relationship built on trust
					and shared goals. Confidentiality is paramount, with a stringent policy in place to safeguard any sensitive information shared by both
					parties—start-ups and SMEs on the one part, and interns and fresh graduates on the other.
				</p>
				<br />
				<p>
					Descinder maintains a zero-tolerance policy against any form of bullying. The platform is envisioned as a collaborative space where
					respect, professionalism, and open communication prevail. This commitment to a positive and inclusive environment is foundational to
					creating an atmosphere conducive to learning, innovation, and professional growth.
				</p>
			</section>
			<section>
				<h4>TERMS OF USE</h4>
				<br />
				<p>Welcome to Descinder! By using our platform, you agree to adhere to the following terms:</p>
				<br />
				<p>
					These Terms apply to all users, (interns, graduates, start-ups & SMEs, including visitors, utilizing our Site. Use of our Site is also
					subject to our current Acceptable Use Policy and Community Guidelines.
				</p>
				<br />
				<p>
					In response to the evolving work landscape, Descinder currently operates with a fully remote internship and working model. This
					necessitates open communication channels that facilitate seamless interactions and timely feedback between start-ups, SMEs, and
					interns. The remote nature of the internships underscores the platform's adaptability to contemporary work trends while emphasizing the
					importance of clear and effective communication to ensure the success of collaborative endeavors.
				</p>
				<br />
				<p>
					As Descinder paves the way for the future of internships and collaborative work experiences, it stands as a testament to the power of
					bringing together emerging talent and ambitious businesses. The platform envisions a future where innovation knows no bounds, driven by
					the collective efforts of start-ups, SMEs, and the bright minds fuelling their growth through experiential learning. Descinder is not
					just a platform; it is a catalyst for positive change, shaping the landscape of professional development and innovation in the business
					world.
				</p>
				<br />

				<h4>SUPPLEMENT</h4>
				<br />
				<p>1. User Registration:</p>
				<br />
				<p className="pad-left">
					<b>Business Users (Start-ups and SMEs):</b> You must create an account, complete user registration, and provide KYC information,
					including Company’s Email Address, Password, Company’s Name, Contact Number, Company Registration Number, Company Sector, Current
					Company Size, Company Logo (Optional), Company’s Website URL (Optional), and a Brief Company Introduction. You can then create
					projects, assign tasks, and approve delivered tasks.
				</p>
				<br />
				<p className="pad-left">
					<b>Early Career Individuals (Interns & Fresh Graduates):</b> You must create an account, complete user registration/profile, and
					provide KYC information, including Email Address, Contact Number, Password, Applicant’s Avatar (Optional), Applicant’s Name, Brief
					Introduction About Yourself, Applicant Type (Intern/Fresh Graduate), Highest Level of Education, Area of Study, Recent Institution
					Attended, Institution ID Card, Employee Details (Employer’s Name, Role/Position, Employment Duration), Your Skills, Interested Role,
					English Proficiency Level, Interested Sector (up to 3), and Resume/CV. You will receive tasks and submit logs.
				</p>
				<br />
				<p className="pad-left">
					<b>Supervisors & Mentors:</b> Receive invites and review & monitor internship lifecycle activities for interns.
				</p>
				<br />
				<p className="pad-left">
					<b>Administrators:</b> Receive and monitor sign-ups/profiles, approve, and decline profiles.
				</p>
				<br />
				<p>The term “user” refers to the above classes of created accounts.</p>

				<br />
				<p>2. KYC Information: All users must provide accurate KYC information as specified in their respective categories.</p>
				<br />
				<p>
					3. User Responsibilities: Users are responsible for maintaining the confidentiality of their login credentials and ensuring the
					accuracy of the provided information.
				</p>
				<br />
				<p>4. Platform Usage: Users agree to use Descinder for its intended purpose and adhere to ethical and legal standards.</p>
				<br />
				<p>5. Profile Approval: Administrators reserve the right to approve or decline user profiles based on the provided information.</p>
				<br />
				<p>6. Task Management: Users are expected to efficiently manage tasks assigned and adhere to project timelines.</p>
				<br />
				<p>
					7. Privacy and Data Security: Descinder prioritizes user privacy and employs security measures to protect user data. Users are
					encouraged to review our Privacy Policy.
				</p>
				<br />
				<p>
					8. Termination: Descinder reserves the right to terminate user accounts that violate terms of use or engage in inappropriate behavior.
				</p>
				<br />
				<br />
				<p>
					By using Descinder, you acknowledge and accept these terms. If you do not agree, please refrain from using the platform. Descinder may
					update these terms periodically, and continued use implies acceptance of any changes.
				</p>
			</section>
			<section>
				<h4>GENERAL TERMS</h4>
				<br />
				<p>
					These general terms govern your use of Descinder's platform and services ("Services"). If any court with authority over these terms
					finds any part unenforceable, both parties agree that the court should modify the terms to make that part enforceable while still
					achieving its intent. If modification is not possible, both parties agree to request the court to remove the unenforceable part and
					still enforce the remaining terms.
				</p>
				<br />
				<p>
					These terms, including any additional terms provided by Descinder when engaging with specific features of the Services, constitute the
					entire agreement between you and Descinder. They supersede all prior agreements for the Services. Descinder's failure to act in
					enforcing a breach of these terms does not waive its right to enforce them in the future.
				</p>
				<br />
				<p>
					You may not assign or transfer these terms (or your membership or use of Services) without Descinder's consent. However, you agree that
					Descinder may assign these terms to its affiliates or a party that acquires it without requiring your consent. There are no third-party
					beneficiaries to these terms.
				</p>
				<br />
				<p>
					These terms are governed by the laws of the United Kingdom, and any disputes arising under or in connection with these terms shall be
					subject to the exclusive jurisdiction of the courts in the United Kingdom.
				</p>
				<br />
			</section>
			<section>
				<h4>ADDITIONAL SITE TERMS</h4>
				<br />
				<p>
					PLEASE READ THE FOLLOWING CAREFULLY. Your use of the product or service implies your agreement to the applicable additional terms
					(referred to as "Additional Terms" in Section 4 and Section 11 of the Site Terms of Service, or “Site Terms”).
				</p>
				<br />
				<p>
					These Additional Terms are governed by and incorporated into the Site Terms. Terms defined in the Site Terms carry the same meaning in
					these Additional Terms. Periodically, we may modify these Additional Terms. In such cases, we will provide notice by posting them on
					our Site and updating the “Last Updated” date. The revised Additional Terms will be effective immediately. By using or continuing to
					use this product or service, you affirm that you have read, understood, and agreed to these Additional Terms.
				</p>
				<br />
				<h4>Implementation/Enforcement</h4>
				<br />
				<p>
					You are responsible for your account and any content you upload. Usage of Descinder Offering is restricted to the limited uses
					specified in our Terms of Service.
				</p>
				<br />
				<p>
					In our sole discretion, we reserve the right to enforce the Terms of Service, including these Community Guidelines, upon identification
					of any content or activity on the Site that violates them. This may involve temporary suspension of your Descinder account while we
					investigate and assess suitable enforcement.
				</p>
				<br />
				<p>In accordance with the gravity of the transgression, Descinder may implement these Community Guidelines through:</p>
				<ul>
					<li>
						<span>I.</span> Issuing a caution.
					</li>
					<li>
						<span>II.</span> Removing user accounts or other materials from our website.
					</li>
					<li>
						<span>III.</span> Temporarily suspending account access for a specified period.
					</li>
					<li>
						<span>IV.</span> Permanently blocking access to accounts.
					</li>
					<li>
						<span>V.</span> Contacting law authorities.
					</li>
					<li>
						<span>VI.</span> Taking any other action at Descinder’s sole discretion.
					</li>
				</ul>
				<br />

				<p>
					Various factors, including the content, severity of conduct, and user history ("strikes"), will be considered when determining the
					enforcement approach. While we generally do not monitor users' off-platform activities, certain situations may warrant consideration,
					such as off-platform behaviour that impacts Descinder's reputation or aligns with Descinder's principles. When off-platform behaviour
					reaches an extreme level, such as joining terrorist or hate groups, sexually abusing children, or engaging in other serious behaviour,
					we will also take that into consideration.
				</p>
				<br />
				<p>
					We reserve the right to make exceptions to our account removal policies under certain conditions, especially in cases of obvious
					satirical, scientific, or educational content that serves the public interest.
				</p>
				<br />
				<p>
					Users commit to assisting Descinder with its investigations by promptly providing any requested information to maintain a safe and
					inviting environment.
				</p>
				<br />
				<p>
					In the event of a permanent account suspension, Descinder will return any held funds, but we may not return any funds held by third
					parties (for instance, fees paid to Apple in connection with an in-app purchase are not held by Descinder and will not be refunded)
					within a reasonable time frame.
				</p>
				<br />

				<h4>The Appeal Process</h4>
				<br />
				<p>
					If you believe that our enforcement decision against you was in error, you have the option to submit a written appeal request to{' '}
					<a href="mailto:info@descinder.com">info@descinder.com.</a>. To initiate the appeal process, you must send an email using the Descinder
					account linked to our enforcement decision, accompanied by a detailed justification for your belief that the judgement was erroneous.
				</p>
				<br />
				<p>
					Descinder retains the exclusive authority to decide whether to allow an appeal. Similar to all other enforcement judgments, this
					decision is final and enforceable.
				</p>
				<br />
				<h4>Restoration Following Suspension</h4>
				<br />
				<p>
					If your Descinder account is suspended due to a breach of the Terms of Service or our Community Guidelines, you have the option to
					request reinstatement once the suspension period is concluded. To initiate the reinstatement process, a written request must be sent
					from the email address linked to the suspended Descinder account to <a href="mailto:info@descinder.com">info@descinder.com</a>.
				</p>
				<br />
				<p>
					<b>The reinstatement request must include:</b>
				</p>
				<br />
				<p>1. An in-depth explanation of your desire to return to Descinder.</p>
				<br />
				<p>
					2. Verification that you have read and agree to be bound by Descinder's Terms of Service, which encompass these terms and community
					guidelines, along with any additional agreements, acknowledgments, or confirmations that may be required.
				</p>

				<br />
				<p>Note:</p>
				<p>
					Requests for reinstatement, in accordance with the above guidelines, do not guarantee approval, as Descinder retains complete authority
					over all decisions.
				</p>
				<br />
				<br />
				<p>
					We may occasionally change our Terms of Service or Community Guidelines to reflect how our Site permits our Users to conduct and
					interact, as well as any new dangers or difficulties that may arise as a result, as Descinders develops with new features and products.
					For the most recent version of these community guidelines, please check back frequently.
				</p>
			</section>
		</Container>
	);
};
export default TermsOfUse;
