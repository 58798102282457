import { Container } from './styles';

const CommunityGuidelines = () => {
	return (
		<Container>
			<header>
				<h1>Community Guidelines</h1>
				<p>Last updated Feb 2022</p>
			</header>

			<section>
				<p>
					At Descinder, building an ideal work environment is at the core of our mission. We strive to provide everyone with a warm, enjoyable,
					and unforgettable experience on Descinder. To achieve this, we have established these Community Guidelines, an integral part of
					Descinder's commitment to creating a safe and collaborative space for all users. These Community Guidelines are enshrined within
					Descinder's comprehensive Terms of Service. By using Descinder, you implicitly accept our Terms of Service, including these Guidelines.
				</p>
				<br />
				<p>
					Creating an ideal work environment is a collaborative effort, and your active involvement helps shape the Descinder community. To
					assist us in maintaining these Guidelines, we kindly request that you report any potential violations. Utilise the online or in-app
					reporting tools, or send an email to <a href="mailto:info@descinder.com">info@descinder.com</a> to report any activity that you believe
					may violate our community standards.
				</p>
				<br />

				<h4>I. UTILISING DESCINDER'S WEBSITE AND ITEMS. </h4>
				<p>
					Please only use Descinder's products and website for their intended purposes. Avoid any actions that could mislead or deceive other
					users, including requesting things that may compromise their integrity or involve trickery/deception. Fee avoidance, such as
					incentivizing transactions off Descinder or through other platforms, is expressly forbidden. Transactions initiated on Descinder must
					be completed here. Users are prohibited from sabotaging another user's business, fabricating reviews, or engaging in any form of
					shilling to artificially enhance visibility or reputation. Refrain from sending unsolicited communications, spam, or disseminating
					false information on Descinder.
				</p>
				<br />

				<h4>II. PROHIBITION OF ILLEGALITY (FRAUD INCLUDED).</h4>
				<p>
					It's critical to realize that laws serve as the foundation for many of our Guidelines, thus understanding this fact is crucial. As a
					result, users are not permitted to use Descinder for any unlawful activity. This includes submitting requests or content that violate
					any law, rule, court order, or legal process. Examples include phishing, money laundering, theft, and fraud attempts. Additionally, the
					selling, advertising, or portrayal of illicit drugs or regulated substances is strictly forbidden. Descinder reserves the right to
					consider the severity of potentially illegal behavior in its sole discretion.
				</p>
				<br />

				<h4>III. DON'T INFRINGE UPON OTHER RIGHTS OR INTELLECTUAL PROPERTY. </h4>
				<p>
					Users must not violate, infringe upon, or misappropriate the rights of others to intellectual property, privacy, publicity, morality,
					or other legal rights. The comprehensive copyright policy is outlined in our Terms of Service. Using Descinder to create a rival
					service or attempting to reverse engineer our software is not allowed. Spidering, harvesting, or scraping any section of our website is
					strictly prohibited.
				</p>
				<br />

				<h4>IV. DON'T INFRINGE UPON PRIVACY RIGHTS. </h4>
				<p>
					Do not compromise the privacy of others by posting personally identifiable information, such as social security numbers or contact
					details. Any content containing personally identifying information of other users or third parties will be promptly removed. Doxxing is
					strictly prohibited.
				</p>
				<br />

				<h4>V. SECURITY. </h4>
				<p>
					Maintaining a secure environment on Descinder is paramount for the well-being of our users and the integrity of our platform. Hacking,
					bypassing security protocols, using automated scripts to gather data, or disseminating harmful elements is forbidden. Any attempt to
					gain unauthorised access to the platform, user accounts, or access sensitive information is strictly forbidden. We encourage users to
					actively participate in maintaining Descinder's security. If you come across any activity that violates these security guidelines,
					please report it promptly. Utilise the in-app or online reporting tools, or email us at{' '}
					<a href="mailto:info@descinder.com">info@descinder.com</a> with details of the violation.
				</p>
				<br />

				<h4>VI. IDENTITY AND AGE CRITERIA.</h4>
				<p>
					Ensuring accurate user identity and compliance with age requirements is crucial for maintaining a trustworthy and secure community on
					Descinder. Users must be at least 18 years old (except for those between 13 and 18, who require parental or legal guardian permission).
				</p>
				<p>
					When creating a Descinder account, provide accurate and truthful information about yourself. Avoid using false identities, inaccurate
					details, or creating accounts with deceptive information.
				</p>
				<br />
				<br />
				<br />

				<p>
					Users are strictly prohibited from using burner accounts, defined as temporary or pseudonymous accounts created to evade enforcement
					procedures. If an account has been banned or is under suspension, attempting to use a different account to circumvent enforcement is
					against our guidelines.
				</p>
				<br />
				<p>
					Users are not permitted to sell or transfer Descinder accounts. Each account is intended for individual use and should not be
					exchanged, sold, or transferred to another user.
				</p>
				<br />
				<p>
					By adhering to these security and identity guidelines, users contribute to the overall safety and integrity of Descinder, fostering a
					community built on trust and authenticity.
				</p>
			</section>

			<section>
				<h4>UPDATES/CHANGES TO THESE TERMS</h4>
				<br />
				<p>
					We reserve the right to modify these Terms. Should any changes occur, we will publish the revised Terms on our Site and update the
					"Last Updated" date at the top of this page. The revised Terms become effective immediately upon your acceptance (e.g., by agreeing
					when creating or logging into your account or by using or continuing to use our Site after the revised Terms have been posted).
					Alternatively, they will be effective 30 days after posting if not explicitly accepted within that period.
				</p>
			</section>
		</Container>
	);
};
export default CommunityGuidelines;
