import { Container } from './styles';

const EmpowerSection = () => {
	return (
		<Container>
			<h3>
				Empower Journeys, Connect Talents <br /> to Start-Ups & SMEs
			</h3>

			<div>
				<div>
					<span>Start-ups & SMEs</span>
					<big>
						Empowering Transitions, <br /> Connecting Talents
					</big>
					<a href="https://app.descinder.com/signup/as-startup">
						<button>get started</button>
					</a>
				</div>
				<div>
					<span>interns & graduate</span>
					<big>
						One-stop for a Diverse <br /> Seasoned Talent{' '}
					</big>
					<a href="https://app.descinder.com/signup/as-intern">
						<button>get started</button>
					</a>
				</div>
			</div>
		</Container>
	);
};

export default EmpowerSection;
